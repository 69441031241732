<template>
  <div>
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="supportCaseData === undefined">
      <h4 class="alert-heading">Error fetching support cases</h4>
      <div class="alert-body">
        No support cases found. Check
        <b-link class="alert-link" :to="{ name: 'apps-support-cases-list' }">
          Support Case List
        </b-link>
        for other support cases.
      </div>
    </b-alert>

    <template v-if="supportCaseData">
      <!-- First Row -->
      <b-breadcrumb class="pl-0 pb-1">
        <b-breadcrumb-item :to="{ name: 'apps-support-cases-list' }">
          Tickets
        </b-breadcrumb-item>
        <b-breadcrumb-item active> Ticket Details </b-breadcrumb-item>
      </b-breadcrumb>
      <b-row>
        <b-col cols="12" xl="12" lg="12" md="12">
          <support-cases-view-info-card
            @refetch-data="refetchData"
            :support-case-data="supportCaseData"
          />
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<script>
import store from "@/store";
import router from "@/router";
import { ref, onUnmounted } from "@vue/composition-api";
import {
  BRow,
  BCol,
  BAlert,
  BLink,
  BBreadcrumb,
  BBreadcrumbItem,
} from "bootstrap-vue";
import supportCasesStoreModule from "../supportCasesStoreModule";
import SupportCasesViewInfoCard from "./SupportCasesViewInfoCard.vue";

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,
    // Local Components
    SupportCasesViewInfoCard,
    BBreadcrumb,
    BBreadcrumbItem,
  },
  methods: {
    refetchData() {
      this.fetchingSupportCases();
    },
  },
  setup() {
    const supportCaseData = ref(null);

    const SUPPORT_CASE_APP_STORE_MODULE_NAME = "app-support-cases";

    // Register module
    if (!store.hasModule(SUPPORT_CASE_APP_STORE_MODULE_NAME))
      store.registerModule(
        SUPPORT_CASE_APP_STORE_MODULE_NAME,
        supportCasesStoreModule
      );

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SUPPORT_CASE_APP_STORE_MODULE_NAME))
        store.unregisterModule(SUPPORT_CASE_APP_STORE_MODULE_NAME);
    });
    store.commit("appConfig/SPINNER_CONFIG", true);
    store
      .dispatch("app-support-cases/fetchSupportCase", {
        id: router.currentRoute.params.id,
      })
      .then((response) => {
        supportCaseData.value = response.data;
        supportCaseData.value.detailedComments.sort(function (a, b) {
          return new Date(b.created) - new Date(a.created);
        });
        store.commit("appConfig/SPINNER_CONFIG", false);
      })
      .catch((error) => {
        store.commit("appConfig/SPINNER_CONFIG", false);
        if (error.response.status === 404) {
          supportCaseData.value = undefined;
        }
      });
    const fetchingSupportCases = () => {
      store.commit("appConfig/SPINNER_CONFIG", true);
      store
        .dispatch("app-support-cases/fetchSupportCase", {
          id: router.currentRoute.params.id,
        })
        .then((response) => {
          supportCaseData.value = response.data;
          supportCaseData.value.detailedComments.sort(function (a, b) {
            return new Date(b.created) - new Date(a.created);
          });
          store.commit("appConfig/SPINNER_CONFIG", false);
        })
        .catch((error) => {
          store.commit("appConfig/SPINNER_CONFIG", false);
          if (error.response.status === 404) {
            supportCaseData.value = undefined;
          }
        });
    };

    return {
      supportCaseData,
      fetchingSupportCases,
    };
  },
};
</script>
