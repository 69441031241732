<template>
  <div>
    <b-card>
      <b-row>
        <!-- Support Case Info: Left col -->
        <b-col
          cols="21"
          xl="6"
          class="d-flex justify-content-between flex-column"
        >
          <!-- Support Case Avatar & Action Buttons -->
          <div class="d-flex justify-content-start">
            <b-avatar
              :src="supportCaseData.avatar"
              :text="avatarText(supportCaseData.name)"
              :variant="`light-${resolveSupportCaseRoleVariant(
                supportCaseData.role
              )}`"
              size="104px"
              rounded
            />
            <div class="d-flex flex-column ml-1">
              <div class="mb-1">
                <h4 class="mb-0">
                  {{ supportCaseData.summary.value }}
                </h4>
                <span class="card-text">{{ supportCaseData.issueKey }}</span>
              </div>
              <div class="d-flex flex-wrap">
                <b-button
                  :to="{
                    name: 'apps-support-cases-edit',
                    params: { id: supportCaseData.issueKey },
                  }"
                  variant="primary"
                >
                  Edit
                </b-button>
              </div>
            </div>
          </div>
        </b-col>

        <!-- Right Col: Table -->
        <b-col cols="12" xl="6">
          <table class="mt-2 mt-xl-0 w-100">
            <caption></caption>
            <tr>
              <th class="pb-50">
                <feather-icon icon="CheckIcon" class="mr-75" />
                <span class="font-weight-bold">Reporter</span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ supportCaseData.reporter.displayName }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon icon="CheckIcon" class="mr-75" />
                <span class="font-weight-bold">Priority</span>
              </th>
              <td class="pb-50 text-capitalize">
                {{ supportCaseData.priority.name }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon icon="LinkIcon" class="mr-75" />
                <span class="font-weight-bold">Status</span>
              </th>
              <td class="pb-50">
                {{ supportCaseData.currentStatus.status }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon icon="LinkIcon" class="mr-75" />
                <span class="font-weight-bold">Request Type</span>
              </th>
              <td class="pb-50">
                {{ requestTypeName }}
              </td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon icon="LinkIcon" class="mr-75" />
                <span class="font-weight-bold">Labels</span>
              </th>
              <td class="pb-50">
                {{ getFormattedValue(supportCaseData.labels[0]) }}
              </td>
            </tr>
          </table>
        </b-col>
        <b-col>
          <div class="mt-1">
            <b-card-text>
              <div
                id="description"
                v-if="supportCaseData.description.renderedValue"
                v-html="supportCaseData.description.renderedValue.html"
              ></div>
              <div
                id="description"
                v-if="!supportCaseData.description.renderedValue"
                v-html="''"
              ></div>
            </b-card-text></div
        ></b-col>
      </b-row>
    </b-card>
    <b-card v-if="attachements.length !== 0">
      <div>
        <h5>Attachments</h5>
        <div class="mt-2">
          <b-card-text>
            <div class="grid">
              <b-card
                id="attachment"
                border-variant="info"
                v-for="(file, i) in attachements"
                :key="i"
              >
                <span @click="viewAttachment(file.file_id)">
                  <img
                    :src="file.thmubnail_url"
                    class="cursor-pointer"
                    alt="attachmentimg"
                  />
                </span>
                <b-card-text>
                  {{
                    file.file_name.length > 20
                      ? file.file_name.substring(0, 15) + "..."
                      : file.file_name
                  }}
                </b-card-text>
              </b-card>
            </div>
          </b-card-text>
        </div>
      </div>
    </b-card>
    <b-card>
      <h5>Comments</h5>
      <div class="mt-2">
        <b-card-text>
          <!-- comment box -->
          <div>
            <b-form-group v-if="!isComment">
              <b-form-textarea
                @click="
                  isComment = true;
                  isCommentEdit = false;
                  editCommentId = '';
                "
                rows="2"
                placeholder="Add Comment"
              />
            </b-form-group>
            <vue-editor
              v-if="isComment"
              id="comment-editor"
              useCustomImageHandler
              @image-added="handleImageAdded"
              v-model="commentContent"
              :editor-toolbar="customToolbar"
            ></vue-editor>
          </div>
          <b-button
            v-if="isComment"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            size="sm"
            variant="primary"
            class="mt-1"
            @click="onCommentSubmit"
            :disabled="commentContent == ''"
          >
            <div class="d-flex align-items-center">
              <b-spinner small label="Text Centered" v-if="Loading"></b-spinner>
              <span class="button-title">Post Comment</span>
            </div>
          </b-button>
          <b-button
            v-if="isComment"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            size="sm"
            class="mt-1 ml-1"
            @click="cancel"
          >
            Cancel
          </b-button>
          <div
            v-for="(comment, ind) in supportCaseData.detailedComments"
            :key="ind"
            class="d-flex align-items-start mb-1 mt-1"
          >
            <b-avatar :src="comment.avatar" size="34" class="mt-25 mr-75" />
            <div class="profile-user-info w-100">
              <div class="d-flex align-items-center">
                <h6 class="mb-0">
                  {{ comment.displayName }}
                </h6>
                <span
                  :title="
                    moment(comment.created).format('Do MMMM YYYY [at] h:mm')
                  "
                  class="ml-1 cursor-pointer"
                  @click="showTime ? (showTime = false) : (showTime = true)"
                  >{{
                    showTime
                      ? moment(comment.created).format("Do MMMM YYYY [at] h:mm")
                      : moment(comment.created).fromNow()
                  }}</span
                >
              </div>
              <small
                id="comment-img"
                v-if="editCommentId !== comment.id"
                v-html="comment.html"
                class="comment"
                >{{
              }}</small>
              <vue-editor
                v-if="isCommentEdit && editCommentId === comment.id"
                id="comment-editor"
                useCustomImageHandler
                @image-added="handleImageAdded"
                v-model="comment.comment"
                :editor-toolbar="customToolbar"
              ></vue-editor>
              <b-button
                v-if="isCommentEdit && editCommentId === comment.id"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                size="sm"
                variant="primary"
                class="mt-1"
                @click="
                  isCommentEdit = false;
                  editCommentId = '';
                "
              >
                Save
              </b-button>
              <b-button
                v-if="isCommentEdit && editCommentId === comment.id"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                size="sm"
                class="mt-1 ml-1"
                @click="
                  isCommentEdit = false;
                  editCommentId = '';
                "
              >
                Cancel
              </b-button>
            </div>
          </div>
        </b-card-text>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BButton,
  BFormTextarea,
  BAvatar,
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BCardText,
  BImg,
  BSpinner,
} from "bootstrap-vue";
import moment from "moment";
import { avatarText } from "@core/utils/filter";
import useSupportCasesList from "../support-cases-list/useSupportCasesList";
import { VueEditor } from "vue2-editor";
import Ripple from "vue-ripple-directive";
import store from "@/store";
import { ref, emit } from "@vue/composition-api";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { useToast } from "vue-toastification/composition";
import axios from "@axios";
import { slice } from "postcss-rtl/lib/affected-props";
// import Editor from "@tinymce/tinymce-vue";

export default {
  components: {
    // editor: Editor,
    BSpinner,
    BCard,
    BButton,
    BRow,
    BCol,
    BAvatar,
    BLink,
    BFormTextarea,
    BFormGroup,
    BCardText,
    VueEditor,
    BImg,
  },
  props: {
    supportCaseData: {
      type: Object,
      required: true,
    },
  },
  directives: {
    Ripple,
  },
  methods: {
    editComment(comment) {
      this.editCommentId = comment.id;
      this.isCommentEdit = true;
    },
  },
  data() {
    return {
      showTime: false,
      moment: moment,
      isComment: false,
      isCommentEdit: false,
      editCommentId: "",
      commentContent: "",
      viewFile: "",
      Loading: false,
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic"],
        [
          { align: "" },
          { align: "center" },
          { align: "justify" },
          { align: "right" },
        ],
        ["blockquote", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
        [{ indent: "-1" }, { indent: "+1" }],
        [{ color: [] }, { background: [] }],
        ["image", "link", "video"],
        ["clean"],
      ],
    };
  },
  methods: {
    handleImageAdded(file, Editor, cursorLocation, resetUploader) {
      let reader = new FileReader();
      reader.onloadend = function () {
        Editor.insertEmbed(cursorLocation, "image", reader.result);
        Editor.formatText(cursorLocation, cursorLocation + 1, "alt", file.name);
        resetUploader();
      };
      reader.readAsDataURL(file);
    },
    getFormattedValue(labels) {
      if (labels) {
        const splitLables = labels.split("_").join(" ");
        const arrLables = splitLables.split(" ");

        for (let label = 0; label < arrLables.length; label++) {
          arrLables[label] =
            arrLables[label].charAt(0).toUpperCase() +
            arrLables[label].slice(1).toLowerCase();
        }

        const labelName = arrLables.join(" ");
        return labelName;
      }
    },
    viewAttachment(id) {
      const config = { responseType: "blob" };
      axios
        .get(`/servicedesk/attachment/content/${id}`, config)
        .then(function (res) {
          let url = URL.createObjectURL(res.data);
          window.open(url);
        })

        .catch(function (err) {
          console.log(err);
        });
    },
    cancel() {
      this.isComment = false;
      this.commentContent = "";
    },
    onCommentSubmit() {
      let data = {
        issueId: this.supportCaseData.issueId,
        comment: this.commentContent,
      };
      this.Loading = true;
      store
        .dispatch("app-support-cases/addCommentByIssueId", data)
        .then(() => {
          this.Loading = false;
          this.$emit("refetch-data");
          this.isComment = false;
          this.commentContent = "";
        })
        .catch(() => {
          this.Loading = false;
          this.isComment = false;
          this.commentContent = "";
        });
    },
  },
  setup(props, { emit }) {
    const { resolveSupportCaseRoleVariant } = useSupportCasesList();
    const toast = useToast();
    let requestTypes = ref([]);
    let requestTypeName = ref("");
    let attachements = ref([]);

    let files = props.supportCaseData?.attachment?.value;
    for (let key in files) {
      const config = { responseType: "blob" };
      axios
        .get(`/servicedesk/attachment/thumbnail/${files[key].id}`, config)
        .then(function (res) {
          let url = URL.createObjectURL(res.data);
          attachements.value.push({
            file_id: files[key].id,
            file_name: files[key].filename,
            thmubnail_url: url,
          });
        })

        .catch(function (err) {
          console.log(err);
        });
    }

    store
      .dispatch("app-support-cases/fetchRequestTypes")
      .then((response) => {
        store.commit("appConfig/SPINNER_CONFIG", false);
        requestTypes.value = response.data.values;

        requestTypes.value.filter(function (value) {
          if (value.id == props.supportCaseData.requestTypeId) {
            requestTypeName.value = value.name;
          }
        });
      })
      .catch(() => {
        store.commit("appConfig/SPINNER_CONFIG", false);
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching request types",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });

    return {
      avatarText,
      resolveSupportCaseRoleVariant,
      requestTypeName,
      attachements,
    };
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/bootstrap-extended/variables";
@import "../supportCaseStyle.scss";

#comment-editor {
  height: 100px;
}
#comment-editor > div.ql-editor > p > img,
#comment-img > img {
  max-width: 450px;
  display: flex;
  margin-bottom: 20px;
}
.ql-editor {
  min-height: 100px !important;
}
.comment {
  font-size: 13px;
  p {
    margin-bottom: 5px;
  }
}
#description {
  min-height: 180px;
  border: 1px solid $custom-control-border-color;
  border-radius: 0.357rem;
  padding: 1rem;
}
#description > img {
  max-width: 450px;
  display: flex;
  margin-bottom: 20px;
}
</style>
